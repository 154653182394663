import React from 'react';
import { Carousel, CarouselScreen, FullViewportStage } from '../../components';
import ScrollTransition from '../../components/Carousel/Transitions/ScrollTransition';
import BackGroundImageStage from '../../components/BackGroundImageStage/BackGroundImageStage';
import { Link } from 'gatsby';
import { getLinkTo, getLocaleFromPathname } from '../../i18n/helpers';

const NotFound = props => {
  const data = props.data.error;
  const { location } = props;
  return (
    <Carousel location={location}>
      <CarouselScreen transition={ScrollTransition}>
        <BackGroundImageStage img={data.image}>
          <FullViewportStage centered>
            <h2 className="typography typography__header2 typography--uppercase">
              {data.errorText}
            </h2>
            <Link
              to={`${getLinkTo(
                getLocaleFromPathname(location.pathname),
                'Home'
              )}`}
            >
              <p className="typography typography__link typography__link.--readMore">
                {data.linkText}
              </p>
            </Link>
          </FullViewportStage>
        </BackGroundImageStage>
      </CarouselScreen>
    </Carousel>
  );
};

export default NotFound;
