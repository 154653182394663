import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import NotFound from './NotFound';

export default props => {
  const data = {
    error: props.data.error.nodes[0],
  };
  return (
    <Layout>
      <NotFound {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    error: allContentfulErrorPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        image {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        errorText
        linkText
      }
    }
  }
`;
